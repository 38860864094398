import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { H1, Paragraph } from 'components/common/Styles';

import { ContentArticle820 } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const AdPolicy = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title={`Ad & Sponsorship Policy`}
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<TopPadding />
						<Paragraph />
						<H1>{`Ad & Sponsorship Policy`}</H1>
						<Paragraph>Written by the BookPhysio.com Team. February 2022. </Paragraph>
						<Paragraph>
							BookPhysio.com’s mission is to be your most trusted ally in your pursuit of health and
							well-being. We’re committed to bringing you authoritative, approachable, and actionable
							content that inspires and guides you toward the best possible health outcomes for you and
							your family.
						</Paragraph>
						<Paragraph>
							The content and experiences produced by BookPhysio.com are created under strict editorial
							and medical guidelines to ensure accuracy, currency, and clarity. BookPhysio.com is funded
							through the sale of advertising that appears on its properties, which include websites,
							apps, social media, and partner publications.
						</Paragraph>
						<Paragraph>
							Be assured that we’re inflexible about our editorial autonomy. Besides input about broad
							topic areas, we do not allow sponsors to influence BookPhysio.com’s editorial content. In
							some cases, BookPhysio.com may offer co-created or branded content in partnership with our
							sponsors and/or present content created by our sponsors.
						</Paragraph>
						<Paragraph>
							This content is always clearly labelled and is detailed in sections 5 and 6 below.
						</Paragraph>
						<Paragraph>
							If you’d like to advertise with BookPhysio.com, contact us{' '}
							<Link to='/contact-us'>here</Link>. These are the specific guidelines we follow about the
							types of advertising we accept:
						</Paragraph>
						<List>
							<Item>
								<Label>
									<Number>1.</Number>
									We review the ads.
								</Label>
								<Paragraph>
									We maintain sole discretion for determining the types of ads that will be accepted
									and displayed on BookPhysio.com properties. We reserve the right to reject, cancel,
									or remove any ads at any time and for any reason. We will provide prompt notice to
									the advertiser upon rejection, cancellation, or removal of any ad, along with an
									explanation. We also reserve the right to determine the appropriate placement of
									advertisements on BookPhysio.com properties.
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>2.</Number>
									We don’t endorse any advertised products or services.
								</Label>
								<Paragraph>
									Although products may appear on our site in the form of advertisements, under no
									circumstances shall our acceptance of any ad be considered an endorsement of the
									product(s) and/or service(s) advertised or for the company that manufactures,
									distributes, or promotes these product(s) or service(s). In some cases,
									BookPhysio.com’s editorial team may rate or rank certain products or services. These
									ratings or rankings are determined solely by the editorial team(s) without regard to
									any financial or advertising relationship that may exist with companies that provide
									the product or services.
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>3.</Number>
									We will not accept certain types of ads.
								</Label>
								<Paragraph>
									We will not accept any ad that is factually inaccurate and/or, in our opinion, in
									poor taste. Advertised products must be in market and readily available.
									BookPhysio.com will not permit, at any time, the placement of any ad for harmful,
									illegal, or objectionable products or services. Ads must not contain fraudulent,
									deceptive, or offensive material, including material that misrepresents, ridicules,
									or attacks an individual or group on the basis of age, colour, national origin,
									race, religion, sex, sexual orientation, or disability. Ads should not contain
									defamatory, libellous, slanderous, or threatening information.
								</Paragraph>
								<Paragraph>
									Ads must not be related to alcohol, firearms, ammunition, fireworks, gambling,
									pornography, tobacco, political campaigns, or the simulation of news or an
									emergency. All ads must clearly identify the advertiser. Any ad that could be
									misconstrued as editorial content will be clearly labelled as an advertisement.{' '}
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>4.</Number> We clearly distinguish ads from editorial content.
								</Label>
								<Paragraph>
									We maintain a distinct separation between advertisements and editorial content in
									every instance. All ads on BookPhysio.com properties are clearly and unambiguously
									identified as such. Clicking on an ad will take the user to an advertiser’s site or
									a sponsor’s resource centre hosted on a BookPhysio.com property. Sponsored resource
									centres are labelled as such, with the name of the sponsoring entity displayed.
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>5.</Number> We clearly distinguish between sponsored and non­-sponsored
									content.
								</Label>
								<Paragraph>
									BookPhysio.com seeks sponsorships from trusted and credible research organisations,
									manufacturers, and other service providers. Sponsorships provide financial benefit
									to BookPhysio.com but also benefit our users by providing current information on
									health services, medications, and treatments. Editorial content sponsored by an
									advertiser is subject to our editorial policy and is reviewed by our editorial staff
									and Medical Affairs team.
								</Paragraph>
								<Paragraph>
									When content (sponsored content, co-created content, or branded content) is provided
									by or influenced by a named sponsor, we take meaningful steps to ensure our users
									will not confuse sponsored content with BookPhysio.com’s original editorial content.
									Native advertising refers to links or ads within a piece of content that link to
									other content. BookPhysio.com may present advertising messages, advertisements, or
									sponsored posts in its social media channels.
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>6.</Number> Affiliate program (e-commerce links in BookPhysio.com content)
								</Label>
								<Paragraph>
									Our health and wellness editors will include links to relevant products they feel
									could be useful to our readers as part of the article creation process. While all
									products are chosen independently, we want you to know that BookPhysio.com may
									receive a payment if you purchase at the retailer’s site within 24 hours of clicking
									on one of the links we provide.
								</Paragraph>
							</Item>
							<Item>
								<Label>
									<Number>7.</Number> We clearly distinguish content that is part of a Partner
									program.
								</Label>
								<Paragraph>
									Occasionally BookPhysio.com will work with products, services, or organisations that
									align with our mission. BookPhysio.com vets these products, services, or
									organisations for quality and only presents what it feels is valuable to and in the
									best interest of our users. BookPhysio.com does not medically review the content and
									is not responsible for providing the products or services described on these pages.
								</Paragraph>
								<Paragraph>
									Any partner content of this type will be labelled with language that indicates it
									did not go through our standard editorial process and follows these Ad and
									Sponsorship Policy guidelines. In the instances where BookPhysio.com receives a
									payment when users purchase or sign up for the product or service, the page may also
									include this language: “BookPhysio.com and our partners may receive a portion of
									revenues if you make a purchase using a link above.” Partner pages may also include
									a “what’s this” link that takes users to a page that explains the nature of the
									partnership.{' '}
								</Paragraph>
							</Item>
						</List>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;

const List = styled.ul`
	list-style-type: none;
	padding-left: 50px;
	@media (max-width: 500px) {
		padding-left: 0;
	}
`;
const Item = styled.li`
	& > p:not(:first-child) {
		margin-left: 20px;
	}
`;

const Label = styled(Paragraph)`
	font-size: 20px;
	line-height: 24px;
	display: flex;
`;

const Number = styled.span`
	font-size: 20px;
	line-height: 24px;
	padding-right: 5px;
`;

export default AdPolicy;
